import React from 'react';
import styled from 'styled-components';

const Cancel = () => <Wrapper>Cancel!</Wrapper>;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

export default Cancel;
